import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";

import { MdFileUpload } from "react-icons/md";
import uploadImage from "../helpers/uploadImage";
import DisplayImage from "./DisplayImage";
import { MdDelete } from "react-icons/md";
import SummaryApi from "../common";
import { toast } from "react-toastify"
import autoProductCtegory from "../helpers/autoProductsCategory";

const UploadAutoProduct = ({ 
    onClose,
    fetchData
   }
) => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    phoneNumber: "",
  });
  

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");

  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];

    const uploadImageCloudinary = await uploadImage(file);

    setData((preve) => {
      return {
        ...preve,
        productImage: [...preve.productImage, uploadImageCloudinary.url],
      };
    });
  };


  const handleDeleteProductImage = async (index) => {
    console.log("image index", index);
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);
    setData((preve) => {
      return {
        ...preve,
        productImage: [...newProductImage],
      };
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((preve) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  {
    /**submin or upload product */
  }
  const handleSubmit = async(e) => {
    e.preventDefault();
    const response = await fetch(SummaryApi.uploadAutoProduct.url,{
      method : SummaryApi.uploadAutoProduct.method,
      credentials : 'include',
      headers : {
        "content-type" : "application/json"
      },
      body : JSON.stringify(data)
    })

    const responseData = await response.json()

    if(responseData.success){
      toast.success(responseData?.message)
      onClose()
      fetchData()
    }

    if(responseData.error){
      toast.error(responseData?.message)
    }


  };



  return (
    <div className="fixed bg-slate-200 bg-opacity-35 w-full h-full top-0 left-0 right-0 bottom-0 flex justify-center items-center">
      <div className="bg-white p-4 rounded pb-10 w-full max-w-2xl h-full max-h-[80%] overflow-hidden ">
        <div className="flex justify-between items-center pb-3">
            <h2 className="font-bold text-lg">Upload Product</h2>
            <div
              className="w-fit ml-auto text-2xl hover:text-white hover:bg-red-600 cursor-pointer"
              onClick={onClose}
            >
              <IoMdClose />
            </div>
          </div>
              <form
              className="grid p-4 gap-2 overflow-y-scroll h-full pb-5"
              onSubmit={handleSubmit}
            >
              <label htmlFor="productName">Product Name :</label>
              <input
                type="text"
                id="productName"
                placeholder="Enter product name"
                name="productName"
                value={data.productName}
                onChange={handleOnChange}
                className="p-2 bg-slate-100 border rounded"
                required
              />

              <label htmlFor="brandName" className="mt-3">
                Brand Name/Location :
              </label>
              <input
                type="text"
                id="brandName"
                placeholder="Enter brand name/Location"
                name="brandName"
                value={data.brandName}
                onChange={handleOnChange}
                className="p-2 bg-slate-100 border rounded"
                
              />

              <label htmlFor="category" className="mt-3">
                Category :
              </label>
              <select
                required
                name="category"
                value={data.autoProductCtegory}
                onChange={handleOnChange}
                className="p-2 bg-slate-100 hover: cursor-pointer border rounded"
                
              >
                <option value={""}>Select Category</option>
                {autoProductCtegory.map((el, index) => {
                  return (
                    <option value={el.value} key={el.value + index}>
                      {el.label}
                    </option>
                  );
                })}
              </select>

              <label htmlFor="productImage" className="mt-3">
                Product Image :
              </label>
              <label htmlFor="uploadImageInput">
                <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer hover:bg-slate-300">
                  <div className="text-blue-500 flex justify-center items-center flex-col gap-2">
                    <span className="text-4xl">
                      <MdFileUpload />
                    </span>
                    <p className="text-sm">Click here to Upload Product Image</p>
                    <input
                      type="file"
                      id="uploadImageInput"
                      className="hidden"
                      onChange={handleUploadProduct}
                    />
                  </div>
                </div>
              </label>
              <div>
                {data?.productImage[0] ? (
                  <div className="flex items-center gap-4">
                    {data.productImage.map((el, index) => {
                      return (
                        <div className="relative">
                          <img
                            src={el}
                            alt={el}
                            width={80}
                            height={80}
                            className="bg-slate-100 border cursor-pointer"
                            
                            onClick={() => {
                              setOpenFullScreenImage(true);
                              setFullScreenImage(el);
                            }}
                          />
                          <div
                            className="absolute bottom-0 right-0 text-white bg-red-500 hover:bg-red-700 hover:text-green-400 cursor-pointer"
                            onClick={() => handleDeleteProductImage(index)}
                          >
                            <MdDelete />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-red-600 text-xs">
                    *please upload product image
                  </p>
                )}
              </div>

              <label htmlFor="price" className="mt-3">
                Price :
              </label>

              <input
                type="number"
                id="price"
                placeholder="Enter Price"
                name="price"
                value={data.price}
                onChange={handleOnChange}
                className="p-2 bg-slate-100 border rounded"
                required
              />
              <label htmlFor="phoneNumber" className="mt-3">
              Phone Number :
              </label>
              <input
                type="number"
                id="phoneNumber"
                placeholder="Enter Phone Number"
                name="phoneNumber"
                value={data.phoneNumber}
                onChange={handleOnChange}
                className="p-2 bg-slate-100 border rounded"
                required
              />

              <label htmlFor="description" className="mt-3">
                Description :
              </label>
              <textarea
                className="h-28 bg-slate-100 border resize-none p-1"
                placeholder="Enter Product Description"
                name="description"
                id="description"
                onChange={handleOnChange}
                value={data.description}
                rows={3}
              
              ></textarea>

              <button className="px-3 py-2 bg-blue-600 text-white mb-6 hover:bg-blue-800">
                Upload product
              </button>
            </form>
            {/**display image full screen */}
            {openFullScreenImage && (
              <DisplayImage
                onClose={() => setOpenFullScreenImage(false)}
                imgUrl={fullScreenImage}
              />
            )}
         
      </div>
    </div>
  )
}

export default UploadAutoProduct
