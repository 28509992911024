import React, { useContext, useState } from 'react'
import loginIcons from '../images/login fav icon.jpg'
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [data, setData] = useState({
      email : "",
      password : ""
    })

    const navigate = useNavigate()
    const {fetchUserDetails, fetchUserAddToCart} = useContext(Context)

    

    const handleOnChange = (e) =>{
      const {name, value} = e.target

      setData((preve)=>{
        return{
          ...preve,
          [name] : value
        }
      })

      
        
    }
    const handleSubmit = async(e) =>{
      e.preventDefault()

      const dataResponse = await fetch(SummaryApi.signIn.url, {
        method : SummaryApi.signIn.method,
        credentials : "include",
        headers : {
          "content-type" : "application/json"
        },
        body : JSON.stringify(data)
      })

      const dataApi = await dataResponse.json()

      if(dataApi.success){
        toast.success(dataApi.message)

        navigate('/')
        fetchUserDetails()
        fetchUserAddToCart()
      }
      if(dataApi.error){
        toast.error(dataApi.message)
      }


    }


    console.log("data login", data)

  return (
    <section id='login'>
      <div className='max-auto-container p-4'>
        <div className='bg-white p-5 w-full max-w-sm mx-auto'>
          <div className='w-20 h-20 mx-auto'>
            <img src={loginIcons} alt='login icons'/>
          </div>

          <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>

            <div className='grid'>

              <label>Email: </label>
              <div className='bg-slate-100 p-2'>
              <input 
                type='email' 
                placeholder='Enter you email' 
                name='email'
                value={data.email}
                onChange={handleOnChange}
                className='w-full h-full outline-none bg-transparent'/>
              </div>

            </div>

            <div>

              <label>Password: </label>
              <div className='bg-slate-100 p-2 flex'>
              <input 
                type={showPassword ? "text" : "password"} 
                placeholder='Enter you password'
                value={data.password}
                name='password' 
                onChange={handleOnChange}
                className='w-full h-full outline-none bg-transparent'/>
              <div className='cursor-pointer text-xl' onClick={()=>setShowPassword((preve)=>!preve)}>
                <span>
                  {
                    showPassword ? (
                      <IoMdEyeOff/>
                    )
                    :
                    (
                      <IoMdEye/>
                    )
                  }  
                </span>
              </div>
              
              </div>
              {/* <Link to={'/forgot-password'} className='block w-fit ml-auto hover:underline hover:text-red-600'>
                forgot password ?
                </Link> */}

            </div>

            <button className='bg-blue-400 hover:scale-700 text-white px-6 py-2 w-full max-w-[150px] hover:bg-blue-800 hover:scale-700 rounded-full mx-auto block mt-6'>Login</button>

          </form>
          {/* <p className='my-6'>Don't have an account? <Link to={"/sign-up"} className='hover:text-yellow-400 hover:underline'>Sign up</Link></p> */}



        </div>
      </div>
    </section>
  )
}

export default Login
